import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import TagListing from "../components/Language/TagListing";

export default function BarrierToEntryTemplate({ pageContext, data }) {
  const { barrierToEntry } = pageContext;
  const languageEdges = data.allLanguagesJson.edges;
  return (
    <Layout>
      <Helmet
        title={`Languages with "${barrierToEntry}" barrierToEntry| ${config.siteTitle}`}
      />
      <TagListing
        tag={barrierToEntry}
        category="Barrier to Entry"
        allLanguagesJson={data.allLanguagesJson}
        languageEdges={languageEdges}
        description="Some languages make it very easy to start writing code, a classic example being writing javascript in your browser console. Others that require a lot of setup, including special programs and installations, have a higher barrier of entry."
      />
    </Layout>
  );
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BarrierToEntryPage($barrierToEntry: String) {
    allLanguagesJson(
      limit: 1000
      sort: { fields: [barrier_to_entry___value], order: DESC }
      filter: { barrier_to_entry: { value: { eq: $barrierToEntry } } }
    ) {
      totalCount
      edges {
        node {
          slug
          name
          description
          used_for
          getting_help {
            value
          }
          pay {
            value
          }
          barrier_to_entry {
            value
          }
          demand {
            value
          }
        }
      }
    }
  }
`;
